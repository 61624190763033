<template>
    <div class="bubbles-wrap">
        <div class="bubbles-container"></div>
    </div>
</template>

<script>
import '@/assets/scss/bubbles.scss';

export default {
  name: 'bubbles',

  beforeCreate() {
    let externalScript2 = document.createElement('script')
    externalScript2.setAttribute('src', 'js/jquery-3.5.1.min.js')
    externalScript2.onload = function () {
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', 'js/bubbles.js')
      document.head.appendChild(externalScript)
    };
    document.head.appendChild(externalScript2)
  }
}
</script>