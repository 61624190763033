<template>
  <div>
    <div class="page-header clear-filter" filter-color="black">
      <parallax class="page-header-image" style="background-image:url('img/bg52.jpg')"/>
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <div class="photo-container">
              <img src="img/avatar.jpg" alt="" class="rounded-circle img-fluid img-raised" />
            </div>
            <h3 class="title">Jürgen Punz</h3>
            <p class="category">Softwareentwickler</p>
            <span><i class="fas fa-map-marker-alt"></i> Manning / Österreich</span><br/>
            <span><i class="fas fa-at"></i> <a style="color: #2CA8FF" href="mailto:juergen@punz-dev.com">juergen@punz-dev.com</a></span><br/>
            <span><i class="fas fa-phone"></i> <a style="color: #2CA8FF" href="tel:+436502157772">+43 (0) 650 215 777 2</a></span>
          </div>
          <div class="col-md-6" style="display: flex; align-items: center">
            <div>
              <h4>Sie wollen Ihre Ideen/Prozesse digitalisieren?</h4>
              <p>Mit Ihnen gemeinsam erstelle ich für Sie die passende Softwarelösung!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="button-container col-md-6 ml-auto mr-auto" style="z-index: 1">
            <a href="https://www.linkedin.com/in/jpunz" target="_blank"
              class="btn btn-primary btn-round btn-md btn-icon"
              rel="tooltip"
              title="LinkedIn Profil anzeigen"
            >
              <i class="fab fa-linkedin-in"></i>
            </a>
            <a
              href="https://www.xing.com/profile/Juergen_Punz" target="_blank"
              class="btn btn-primary btn-round btn-md btn-icon"
              rel="tooltip"
              title="Xing Profil anzeigen"
            >
              <i class="fab fa-xing"></i>
            </a>
          </div>
          <div class="button-container col-md-6" style="z-index: 1">
            <a href="#contact" class="btn btn-primary btn-round btn-md" v-smooth-scroll>Unverbindlich kontaktieren</a>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <card>
              <h4 class="card-title" style="margin-top: 0">Leistungen</h4>
              <p style="margin-bottom: 0">
                Native Apps (Android, iOS)<br/>
                Cross Platform Apps (Android, iOS, Web)<br/>
                Web Applikationen<br/>
                Client/Server Applikationen
              </p>
            </card>
          </div>

          <div class="col-md-6">
            <bubbles style="opacity: 0.5; position: relative;"/>
            <card style="background-color:rgba(0, 0, 0, 0);">
              <h4 class="card-title" style="margin-top: 0">Technologien*</h4>
              <div>
                <badge type="info">Swift</badge> <badge type="info">Objective-C</badge> <badge type="info">Java</badge> <badge type="info">Kotlin</badge><br/> 
                <badge type="info">Flutter</badge> <badge type="info">Dart</badge> <badge type="info">Vue.js</badge> <badge type="info">Node.js</badge><br/>
                <badge type="info">C# .net</badge> <badge type="info">WordPress</badge> <badge type="info">Microsoft SQL</badge> <badge type="info">MySQL</badge><br/>
                <small>* Weitere Technologien in <router-link to="/about">Über mich</router-link></small>
              </div>
            </card>
          </div>
        </div>
        <h4 class="text-center" style="margin-bottom: -8px;">Der gemeinsame Weg zu Ihrer Softwarelösung</h4>
        
        <n-pagination class="justify-content-center carousel-pagination" style="margin-bottom: -8px; margin-top: 16px;" :page-count="5" v-model="defaultPagination" @input="goToCarouselSlide"/>

        <div class="row">
        <carousel-3d ref="carousel" style="margin-top: 0" height="385" :controls-visible="true" :loop="false" :controlsPrevHtml="arrowLeft" :controlsNextHtml="arrowRight" @before-slide-change="onSlideChange">
          <slide class="card" :index="0">
            <div class="carousel-title">
              <div class="carousel-nr">1.</div>
              <h6 class="text-muted">Erstgespräch</h6>
            </div>
            <hr>
            <div class="card-footer">Im unverbindlichen Erstgespräch ist es mir wichtig, Sie/Ihr Unternehmen kennenzulernen. Um die passende, digitale Lösung für Sie zu finden, versuche ich Ihre Ideen/Prozesse, die Use Cases und die künftigen Hauptanwender zu verstehen.</div>
          </slide>
          <slide class="card" :index="1">
            <div class="carousel-title">
              <div class="carousel-nr">2.</div>
              <h6 class="text-muted">Konzept</h6>
            </div>
            <hr>
            <div class="card-footer">Die Anforderungen aus dem Erstgespräch werden analysiert, daraus eine Idee für Ihre individuelle Software geboren und anschließend mit Ihnen besprochen und abgestimmt.</div>
          </slide>
          <slide class="card" :index="2">
            <div class="carousel-title">
              <div class="carousel-nr">3.</div>
              <h6 class="text-muted">Entwicklung</h6>
            </div>
            <hr>
            <div class="card-footer">Wenn das Software Konzept auch Ihren Vorstellungen entspricht, startet die Softwareentwicklung. In diesem Prozess werden auch Sie eingebunden. Die Entwicklung findet in sogenannten Sprints statt. Vor jedem Sprint wird mit Ihnen besprochen, welche Teile der Software wie umgesetzt werden. Nach jedem Sprint wird Ihnen der aktuelle Stand der Software präsentiert und Ihnen zur Verfügung gestellt. Damit haben Sie die Möglichkeit, Feedback und Änderungswünsche rechtzeitig einzureichen.</div>
          </slide>
          <slide class="card" :index="3">
            <div class="carousel-title">
              <div class="carousel-nr">4.</div>
              <h6 class="text-muted">Inbetriebnahme</h6>
            </div>
            <hr>
            <div class="card-footer">Nach Fertigstellung Ihres Softwareprodukts findet die Inbetriebnahme statt. Gerne stehe ich Ihnen auch zur Verfügung, um Ihre Anwender einzuschulen.</div>
          </slide>
          <slide class="card" :index="4">
            <div class="carousel-title">
              <div class="carousel-nr">5.</div>
              <h6 class="text-muted">Wartung & Betreuung</h6>
            </div>
            <hr>
            <div class="card-footer">Natürlich lasse ich Sie nach der Fertigstellung der Software nicht im Stich. Regelmäßig wird überprüft, ob die Software und das zugrundeliegende System dem „State of the Art“ entspricht. Außerdem stehe ich Ihnen für Hilfe und Unterstützung zur Verfügung.<br><br>Gerne bleibe ich weiterhin mit Ihnen in Kontakt, um bei Bedarf Erweiterungen, Anpassungen oder ein neues Projekt mit Ihnen umsetzen zu können.</div>
          </slide>
        </carousel-3d>
        </div>
        <div class="section-contact-us text-center">
          <h4 id="contact">Treten Sie mit mir in Kontakt</h4>

          <div class="row">
            <div class="col-md-6">
              <card class="text-center">
                <fg-input
                  style="margin-top: 10px"
                  class="input-lg"
                  placeholder="Name*"
                  v-model="form.name"
                  addon-left-icon="now-ui-icons users_circle-08"
                >
                </fg-input>
                <fg-input
                  class="input-lg"
                  placeholder="Firma"
                  v-model="form.company"
                  addon-left-icon="now-ui-icons business_briefcase-24"
                >
                </fg-input>
                <fg-input
                  class="input-lg"
                  placeholder="Email*"
                  v-model="form.email"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                >
                </fg-input>
                <fg-input
                  class="input-lg"
                  placeholder="Telefon"
                  v-model="form.phone"
                  addon-left-icon="now-ui-icons tech_mobile"
                >
                </fg-input>
                <div class="textarea-container">
                  <textarea
                    class="form-control"
                    name="name"
                    rows="8"
                    cols="80"
                    v-model="form.message"
                    placeholder="Ihre Nachricht*"
                  ></textarea>
                </div>
                <div class="send-button">
                  <n-button :disabled="form.name === '' || form.email === '' || form.message === ''" v-show="!mailSent" type="primary" round block size="lg" @click="sendMail">Nachricht senden</n-button>
                </div>
                <alert type="success" v-show="mailSent" style="margin: 10px -20px -20px -20px">
                  <strong>Vielen Dank für Ihre Nachricht!</strong><br/>Ich werde Ihre Anfrage so schnell wie möglich bearbeiten.
                </alert>
                <alert type="danger" v-show="mailFailed" style="margin: 10px -20px -20px -20px">
                  <strong>Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten.</strong><br/>Bitte versuchen Sie es erneut oder verwenden Sie einer der anderen Kontaktmöglichkeiten (Email, Telefon).
                </alert>
              </card>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-6">
                <card class="contact-card"><a href="mailto:juergen@punz-dev.com">
                  <h1 style="margin-bottom: 0"><i class="fas fa-envelope"></i></h1>
                  juergen@punz-dev.com
                </a></card></div>
                <div class="col-6">
                <card class="contact-card"><a href="tel:+436502157772">
                  <h1 style="margin-bottom: 0"><i class="fas fa-phone"></i></h1>
                  +43 (0) 650 215 777 2
                </a></card></div>
                <div class="col-6" style="padding-top: 15px">
                <card class="contact-card"><a href="vcard.vcf" download type="text/vcard">
                  <h1 style="margin-bottom: 0"><i class="fas fa-id-card"></i></h1>
                  vCard
                </a></card></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Alert, Button, FormGroupInput, Card, Badge, Bubbles, Pagination } from '@/components';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import axios from "axios";
import qs from 'qs';

export default {
  name: 'profile',
  bodyClass: 'main',
  components: {
    Alert,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Card,
    Badge,
    Bubbles,
    Carousel3d,
    Slide,
    [Pagination.name]: Pagination
  },
  data() {
    return {
      form: {
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
      },
      defaultPagination: 1,
      mailSent: false,
      mailFailed: false,
      arrowRight: '<button class="btn btn-simple btn-round btn-md btn-icon btn-primary"><i class="fas fa-chevron-right"></i></button>',
      arrowLeft: '<button class="btn btn-simple btn-round btn-md btn-icon btn-primary"><i class="fas fa-chevron-left"></i></button>'
    };
  },
  methods: {
    sendMail() {
      this.mailFailed = false;

      const mail = { name: this.form.name, company: this.form.company, phone: this.form.phone, email: this.form.email, message: this.form.message };
      axios.create({
        baseURL: "https://websiteapi.punz-dev.com"
      }).post("/sendmail", qs.stringify(mail))
      .then(response => this.mailSent = true)
      .catch((e) => this.mailFailed = true);
    },
    goToCarouselSlide(index) {
      this.$refs.carousel.goSlide(index-1)
    },
    onSlideChange(index) {
      this.defaultPagination = index + 1;
    }
  }
};
</script>