<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container row ml-auto mr-auto">
      <div class="col-6" style="padding: 0">
        <i class="now-ui-icons users_single-02"></i>&nbsp;&nbsp;Ing. Jürgen Punz, MSc<br/>
        <i class="now-ui-icons location_pin"></i>&nbsp;&nbsp;Wolfshütte 96, A-4903 Manning<br/>
        <i class="now-ui-icons ui-1_email-85"></i>&nbsp;&nbsp;<a href="mailto:juergen@punz-dev.com">juergen@punz-dev.com</a><br/>
        <i class="now-ui-icons tech_mobile"></i>&nbsp;&nbsp;<a href="tel:+436502157772">+43 (0) 650 215 777 2</a>
      </div>
      <div class="col-6 copyright" style="padding: 0">
        &copy; {{ year }} Jürgen Punz.<br>Softwareentwicklung mit <i class="fas fa-heart heart text-danger"/> aus Oberösterreich.<br/><br/>
        <router-link to="/imprint">Impressum</router-link> | <router-link to="/privacy">Datenschutz</router-link>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
