<template>
  <div class="section">
    <div class="container">
      <h4>Referenzen</h4>
      <div class="row">
        <div class="col-md-6">
        <card> 
            <div class="row">
            <div class="col-md-2">
                <img src="img/about.jpg" alt="Image" />
            </div>
            <div>
                <h6 style="margin-bottom: 0.2rem; padding-top: 15px;">Mobile Banking Apps</h6>
                <h6 style="font-weight: normal;">Star Finanz-Software Entwicklung und Vertriebs GmbH</h6>
            </div>
            </div>
        </card>
        </div>

        <div class="col-md-6">
          <card> 
            <div class="row">
            <div class="col-md-2">
                <img src="img/about.jpg" alt="Image" />
            </div>
            <div>
                <h6 style="margin-bottom: 0.2rem; padding-top: 15px;">Mobile Banking Apps</h6>
                <h6 style="font-weight: normal;">Star Finanz-Software Entwicklung und Vertriebs GmbH</h6>
            </div>
            </div>
        </card>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h4>Werdegang</h4>
          <timeline style="padding-bottom: 0px" :items="timeline"/>
        </div>
        <div class="col-md-6">
          <h4>Weiteres</h4>
          <card style="margin-bottom: 10px">
            <h6 style="margin-bottom: 0.2rem; padding-top: 15px;">Ehrenamtliche Tätigkeit</h6>
            <h6 style="font-weight: normal;">Samariter-Wunschfahrt</h6>
            <hr style="margin-top: 0; margin-bottom: 10px">
                
            Die Mitwirkung als Wunscherfüller bei der Samariter-Wunschfahrt ist mir ein besonderes Anliegen. Mit der Wunschfahrt wird schwer erkrankten Kindern, Jugendlichen und Erwachsenen in ihrer letzten Lebensphase der letzte Wunsch erfüllt.
            <div class="timeline-panel-controls">
              <div class="timestamp">seit Oktober 2018</div>
            </div>
          </card>
          <card style="margin-bottom: 10px">
            <h6 style="margin-bottom: 0.2rem; padding-top: 15px;">Tutor</h6>
            <h6 style="font-weight: normal;">FH Oberösterreich - Campus Hagenberg</h6>
            <hr style="margin-top: 0; margin-bottom: 10px">
                
            Als Tutor durfte ich mehrere Studenten bei deren Semesterprojekten des Bachelorstudiengangs Mobile Computing betreuen.
            <div class="timeline-panel-controls">
              <div class="timestamp">November 2016 - Februar 2018</div>
            </div>
          </card>
          <card style="margin-bottom: 10px">
            <h6 style="margin-bottom: 0.2rem; padding-top: 15px;">Ehrenamtliche Tätigkeit</h6>
            <h6 style="font-weight: normal;">Samariterbund Linz</h6>
            <hr style="margin-top: 0; margin-bottom: 10px">
                
            Seit meinem Zivildienst engagiere ich mich ehrenamtlich beim Samariterbund Linz. Diese Tätigkeit zählt zu meinen größten Hobbies.<br/>Dabei bin ich in folgenden Bereichen tätig:
            <ul style="margin-bottom: 0">
              <li>Rettungsdienst</li>
              <li>Rettungsleitstelle</li>
              <li>Ambulanzdienste & Katastrophenhilfsdienst</li>
              <li>Ausbildner Einsatzlenker</li>
              <li>Impf- & Testtraße</li>
              <li>...</li>
            </ul>
            <div class="timeline-panel-controls">
              <div class="timestamp">seit Juli 2009</div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Timeline } from '@/components';

export default {
  name: 'references',
  bodyClass: 'main',
  components: {
    Card,
    Timeline
  },
  data() {
    return { 
      timeline: [
        {
              icon_class: 'now-ui-icons business_briefcase-24',
              icon_status: 'primary',
              title: 'CTO',
              subtitle: 'MatheArena',
              created: 'April 2021 - November 2021',
              body: 'Entwicklung einer Cross Platform App zum Lernen und Üben von Mathematik (Sekundarstufe 1 und 2) als Ergänzung zum klassischen Schulbuch.<ul><li>Technische Leitung</li><li>Vertrieb</li></ul>',
              technologies: ['Flutter', 'Dart', 'Java', 'MySQL', 'Android', 'iOS', 'Linux', 'Firebase', 'Jira', 'Confluence']
        },
        {
              icon_class: 'now-ui-icons business_briefcase-24',
              icon_status: 'primary',
              title: 'Teamleiter, Product Owner, Softwareentwickler',
              subtitle: 'ITPRO - Consulting & Software GmbH',
              created: 'April 2018 - September 2021',
              body: 'Entwicklung von Individualsoftware<br/>Teamleiter<br/>Mehrfacher Product Owner<br/>Interner Technologieverantwortlicher für iOS<br/>Bestandteil des Mitarbeiterrecruitings<br/>Betreuung Studentenprojekte',
              technologies: ['C# .net', 'Java', 'Kotlin', 'Swift', 'Objective-C', 'Angular', 'Unity', 'PHP', 'Microsoft SQL', 'MySQL', 'Oracle', 'Android', 'iOS', 'Windows Server', 'Linux', 'RFID', 'GPS Tracking', 'Firebase', 'Jenkins', 'SonarQube', 'Azure DevOps', 'SOTI MobiControl']
        },
        {
              icon_class: 'now-ui-icons business_briefcase-24',
              icon_status: 'primary',
              title: 'Softwareentwickler',
              subtitle: 'Selbständig',
              created: 'August 2016 - April 2018',
              body: 'Beratung, Angebotslegung, Planung und Entwicklung von iOS- und Android-Applikationen.',
              technologies: ['Java', 'Swift', 'Android', 'iOS', 'Jira']
          },
        {
              icon_class: 'now-ui-icons business_briefcase-24',
              icon_status: 'primary',
              title: 'Mobile App Entwickler (Auslandspraktikum)',
              subtitle: 'Flipside Group',
              technologies: ['Swift', 'Objective-C', 'iOS', 'Jira'],
              created: 'Februar 2016 - Mai 2016',
              body: 'Berufpraktikum für mein Bachelorstudium.<br/>Entwicklung von iOS-Applikationen unter Einsatz von Scrum.'
          },
          {
              icon_class: 'now-ui-icons education_hat',
              icon_status: 'secondary',
              title: 'Mobile Computing (Bachlor & Master)',
              subtitle: 'FH Oberösterreich - Campus Hagenberg',
              technologies: ['Java', 'Swift', 'Objective-C', 'Unity', 'Xamarin', 'MySQL', 'Android', 'iOS', 'Firebase'],
              created: 'Oktober 2013 - Juli 2018',
              body: "Masterarbeit: Traffic Flow Analysis based on Web-Map Applications<br/>Bachelorarbeit: Verwendung eines Eye Trackers zur Steuerung von Smartphones"
          },
        {
              icon_class: 'now-ui-icons business_briefcase-24',
              icon_status: 'primary',
              title: 'Softwareentwickler',
              subtitle: 'Premedia GmbH',
              technologies: ['C# .net', 'ASP.net', 'Joomla', 'Microsoft SQL', 'Oracle', 'Windows Server', 'Jira'],
              created: 'Mai 2010 - Jänner 2016',
              body: 'Projektleitung, Kundenbetreuung (Support) und -schulung.<br/>Entwicklung von Webshops und Umsetzung interner Projekte.<br/>Produktverantwortlicher für diverse Fertiglösungen.'
          },
          {
              icon_class: 'now-ui-icons education_hat',
              icon_status: 'secondary',
              title: 'Elektronik und technische Informatik',
              subtitle: 'HTBLA Leonding',
              technologies: ['C', 'Java'],
              created: 'September 2004 - Juli 2009'
          },
      ]
    }
  },
};
</script>
