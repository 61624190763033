<template>
  <navbar ref="navbar"
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" to="/"><!-- :v-show="showTitle" -->
        <img src="img/avatar.jpg" alt="" class="rounded-circle" style="height: 30px; margin-right: 5px" /> Jürgen Punz
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link class="nav-link" to="/" @click.native="closeNav">
          <i class="now-ui-icons shopping_shop"></i>
          <p>Start</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/about" @click.native="closeNav">
          <i class="now-ui-icons users_single-02"></i>
          <p>Über mich</p>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link class="nav-link" to="/references" @click.native="closeNav">
          <i class="now-ui-icons business_briefcase-24"></i>
          <p>Referenzen</p>
        </router-link>
      </li> -->

      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="LinkedIn Profil anzeigen"
          data-placement="bottom"
          href="https://www.linkedin.com/in/jpunz"
          target="_blank"
        >
          <i class="fab fa-linkedin"></i>
          <p class="d-lg-none d-xl-none">LinkedIn</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Xing Profil anzeigen"
          data-placement="bottom"
          href="https://www.xing.com/profile/Juergen_Punz"
          target="_blank"
        >
          <i class="fab fa-xing-square"></i>
          <p class="d-lg-none d-xl-none">Xing</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
  },
  // mounted() {
  //   console.log(Boolean(this.$refs.navbar.isTransparent));
  // },
  methods: {
    closeNav() {
      this.$refs.navbar.close();
    }
  },
  computed: {
    // showTitle() {
    //   if(this.$refs.navbar)
    //     return !Boolean(this.$refs.navbar.isTransparent);

    //   return true;
    // }
  }
};
</script>

<style scoped></style>
