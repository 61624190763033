<template>
    <ul class="timeline">
        <component :is="'timeline-item'"
        v-for="(item, index) in items" 
        :key="index"
        :item="item"/>
    </ul>
</template>
<script>
import '@/assets/scss/timeline.scss';
import TimelineItem from './TimelineItem.vue';

export default {
    name: 'timeline',
    components: {
        TimelineItem
    },
    props: ["items"],
    events: {
        'delete-item': function() {
            return true; // forward to parent
        }
    }
}
</script>