import Vue from 'vue';
import Router from 'vue-router';
import Home from './pages/Home.vue';
import About from './pages/About.vue';
import References from './pages/References.vue';
import Imprint from './pages/Imprint.vue';
import Privacy from './pages/Privacy.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      components: { default: Home, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 260 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/about',
      name: 'about',
      components: { default: About, header: MainNavbar, footer: MainFooter },
      props: {
        footer: { backgroundColor: 'black' }
      }
    },
    // {
    //   path: '/references',
    //   name: 'references',
    //   components: { default: References, header: MainNavbar, footer: MainFooter },
    //   props: {
    //     footer: { backgroundColor: 'black' }
    //   }
    // },
    {
      path: '/imprint',
      name: 'imprint',
      components: { default: Imprint, header: MainNavbar, footer: MainFooter },
      props: {
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      components: { default: Privacy, header: MainNavbar, footer: MainFooter },
      props: {
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
