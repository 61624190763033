<template>
    <li :class="'timeline-item ' + item.action_needed">
        <div :class="'timeline-badge ' + item.icon_status"><i :class="item.icon_class"></i></div>
        <div :class="'timeline-panel ' + item.element_status + ' ' + item.element_day_marker">
            <div class="timeline-heading">
                <h6 style="margin-bottom: 0.2rem; padding-top: 15px;" :class="'timeline-title ' + item.text_status">{{ item.title }}</h6>
                <h6 style="font-weight: normal;" :class="'timeline-title ' + item.text_status">{{ item.subtitle }}</h6>
                <hr style="margin-top: 0; margin-bottom: 10px">
                <component v-for="(technology, index) in item.technologies" :key="index" :is="'badge'" type="info" style="margin-right: 3px">
                    {{ technology }}
                </component>
                <div class="timeline-panel-controls">
                    <div class="controls">
                        <component 
                            v-for="(control, index) in item.controls" 
                            :is="'timeline-control'" :key="index"
                            :control="control"/>
                    </div>
                    <div class="timestamp">{{ item.created }}</div>
                </div>
            </div>
             <el-collapse v-if="item.body">
                <el-collapse-item title="Details" name="1"><div v-html="item.body"/></el-collapse-item>
             </el-collapse>
        </div>
    </li>
</template>
<script>
import { Badge } from '@/components';
import { Collapse, CollapseItem } from 'element-ui';
import TimelineControl from './TimelineControl.vue';

export default {
    name: "timeline-item",
    components: {
        Badge,
        TimelineControl,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem
    },
    props: {
        item: {
            action_needed: String,
            icon_status: String,
            element_status: String,
            text_status: String,
            icon_class: String,
            element_day_marker: String,
            title: String,
            controls: Array,
            created: String,
            body: String,
            technologies: [String]
        }
    },
    methods: {
        delete: function() {
            this.$dispatch('timeline-delete-item', this.item.id)
        },
        
        edit: function() {
            
        }
    },
    events: {
        'timeline-delete': function() {
            this.delete();
        },
        
        'timeline-edit': function() {
            this.edit();
        }
    }
}
</script>